export default {
    border: '1px solid #d9d9d9',
    borderRadius: 2,
    transition: 'all 0.3s',

    '&:focus': {
        outline: 0,
        boxShadow: '0 0 0 2px rgb(24 144 255 / 20%)'
    },

    '&:hover': {
        borderColor: '#40a9ff',
    },
}
