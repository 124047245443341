import {useTableContext} from '../TableContext.jsx'
import TopButton from './TopButton.jsx'

const ButtonDeleteRows = ({children = '删除', ...props}) => {
    const table = useTableContext()

    const handleClick = () => {
        table.deleteRows(table.rowSelection?.selectedRowKeys)
    }

    return (
        <TopButton
            minRows={1}
            onClick={handleClick}
            {...props}
        >{children}</TopButton>
    )
}

export default ButtonDeleteRows
