import {css} from '@emotion/react'
import {CheckOutlined} from '@ant-design/icons'

const cssViewBoolean = css({
    display: 'block',
    textAlign: 'center',
})

const ViewBoolean = ({value, ...props}) => {
    if (value) {
        return (
            <CheckOutlined
                css={cssViewBoolean}
                {...props}
            />
        )
    }
    else {
        return null
    }
}

export default ViewBoolean
