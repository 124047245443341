import {css} from '@emotion/react'
import dayjs from 'dayjs'

const cssViewDate = css({
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const ViewDate = ({format = 'YYYY-MM-DD', value, ...props}) => {
    const text = value ? dayjs(value).format(format) : ''

    return (
        <div
            css={cssViewDate}
            title={text}
            {...props}
        >
            {text}
        </div>
    )
}

export default ViewDate
