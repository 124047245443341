import {css} from '@emotion/react'
import {DeleteOutlined, PlusOutlined, SnippetsOutlined} from '@ant-design/icons'
import ButtonChooseImages from '@/components/ChooseImages/ButtonChooseImages.jsx'
import ButtonPasteImage from '@/components/ChooseImages/ButtonPasteImage.jsx'
import Image from './Image.jsx'
import stopPropagation from '../../stopPropagation.mjs'

const cssThumbnail = css({
    position: 'relative',
    cursor: 'zoom-in',
    marginRight: 4,

    '&:hover::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: '#00000050',
        pointerEvents: 'none',
    },

    '& .ant-image-mask': {
        display: 'none',
    },
})

const cssDelete = css({
    position: 'absolute',
    top: 4,
    right: 4,
    color: '#fff',
    zIndex: 1,
    visibility: 'hidden',

    'div:hover>&': {
        visibility: 'unset',
    },
})

const cssPicker = css({
    display: 'flex',
})

const cssButtons = css({
    display: 'flex',
    flexDirection: 'column',
})

const Thumbnail = ({onDelete, ...props}) => {
    const handleDelete = () => onDelete(props.value)

    return (
        <div css={cssThumbnail}>
            <Image {...props} />

            <DeleteOutlined
                css={cssDelete}
                title="删除"
                onClick={handleDelete}
            />
        </div>
    )
}

const ImagePicker = ({
    height = 50,
    maxCount = Number.MAX_SAFE_INTEGER,
    value,
    width = 50,
    onChange,
}) => {
    const isDisabled = (() => {
        if (1 === maxCount) {
            return Boolean(value)
        }
        else if (1 < maxCount) {
            return maxCount <= value?.length
        }
        else {
            return true
        }
    })()

    const handleChoose = (files) => {
        if (0 < files.length) {
            if (1 < maxCount) {
                const oldFiles = value ?? []
                const newCount = maxCount - oldFiles.length
                const newFiles = files.slice(0, newCount)
                onChange?.([...oldFiles, ...newFiles])
            }
            else {
                onChange?.(files[0])
            }
        }
    }

    const handlePaste = (file) => {
        if (1 < maxCount) {
            onChange?.([...value, file])
        }
        else {
            onChange?.(file)
        }
    }

    const handleDelete = (item) => {
        if (1 < maxCount) {
            onChange(value.filter((e) => e !== item))
        }
        else {
            onChange(null)
        }
    }

    const styleButton = {
        width: height / 2,
        height: height / 2,
    }

    const images = (() => {
        if (value) {
            if (Array.isArray(value)) {
                return value.map((v, i) => (
                    <Thumbnail
                        key={i}
                        value={v}
                        width={width}
                        height={height}
                        onDelete={handleDelete}
                    />
                ))
            }
            else {
                return (
                    <Thumbnail
                        value={value}
                        width={width}
                        height={height}
                        onDelete={handleDelete}
                    />
                )
            }
        }
        else {
            return null
        }
    })()

    return (
        <div
            css={cssPicker}
            onClick={stopPropagation}
        >
            {images}

            <div css={cssButtons}>
                <ButtonChooseImages
                    style={styleButton}
                    disabled={isDisabled}
                    icon={<PlusOutlined />}
                    multiple={1 < maxCount}
                    title="选择图片"
                    onChange={handleChoose}
                />

                <ButtonPasteImage
                    style={styleButton}
                    disabled={isDisabled}
                    icon={<SnippetsOutlined />}
                    title="粘贴图片"
                    onChange={handlePaste}
                />
            </div>
        </div>
    )
}

export default ImagePicker
