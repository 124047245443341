import {useCallback, useEffect, useState} from 'react'

const defaultShouldUpdate = (data, draft) => true

const useDraft = (data, shouldUpdate = defaultShouldUpdate) => {
    const [draft, setDraft] = useState(data)
    const [isChanged, setIsChanged] = useState(false)

    useEffect(
        () => {
            if (shouldUpdate(data, draft)) {
                setDraft(data)
                setIsChanged(false)
            }
        },

        [data]
    )

    const setDraftWithState = useCallback(
        (draft) => {
            setDraft(draft)
            setIsChanged(true)
        },

        []
    )

    const discard = useCallback(
        () => {
            setDraft(data)
            setIsChanged(false)
        },

        [data]
    )

    return {
        draft,
        discard,
        isChanged,
        setDraft: setDraftWithState,
        setIsChanged,
    }
}

export default useDraft
