export default (els, root = document) => {
    return new Promise((resolve) => {
        const callback = entries => {
            const rects = []

            for (const {boundingClientRect} of entries) {
                rects.push(boundingClientRect)
            }

            observer.disconnect()
            resolve(rects)
        }

        const options = {root}
        const observer = new IntersectionObserver(callback, options)

        for (const el of els) {
            observer.observe(el)
        }
    })
}
