import {Button, Modal} from 'antd'
import {chooseFiles} from '@/script/file.mjs'

const ButtonChooseImages = ({multiple, onChange, ...props}) => {
    const handleClick = async () => {
        try {
            const files = await chooseFiles.image({multiple})
            onChange(files)
        }
        catch (err) {
            Modal.error({content: err.message})
        }
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}

export default ButtonChooseImages
