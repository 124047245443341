import {css} from '@emotion/react'

const cssContextMenuItem = css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: 12,
    minHeight: 32,
    padding: '0 16px',
    backgroundColor: '#fff',

    '&:hover': {
        backgroundColor: '#fafafa',
    },
})

const cssContextMenuItemDisabled = css({
    color: '#00000040',

    '&:hover': {
        backgroundColor: '#fff',
    },
})

const cssContextMenuItemSuffix = css({
    display: 'flex',
    justifyContent: 'flex-end',
})

export default function ContextMenuItem({
    disabled,
    label,
    prefix,
    suffix,
    onClick,
    ...props
}) {
    const csss = [cssContextMenuItem]

    if (disabled) {
        csss.push(cssContextMenuItemDisabled)
    }

    const handleClick = (e) => {
        if (disabled) {
            return
        }

        onClick?.(e)
    }

    return (
        <div
            css={csss}
            onClick={handleClick}
            {...props}
        >
            <div className="hd-context-menu__item__prefix">
                {prefix}
            </div>

            {label}

            <div
                css={cssContextMenuItemSuffix}
                className="hd-context-menu__item__suffix"
            >
                {suffix}
            </div>
        </div>
    )
}
