import {useEffect} from 'react'

const MIN_WIDTH = 10

export default (refEl, table, columnKey) => {
    useEffect(
        () => {
            const th = refEl.current.closest('th')
            const container = th.closest('.ant-table-container')
            const resizer = document.createElement('div')

            Object.assign(resizer.style, {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                width: '2px',
                cursor: 'ew-resize',
            })

            const cols = []
            let w = 0

            resizer.addEventListener('pointerdown', e => {
                resizer.setPointerCapture(e.pointerId)

                const i = Array.prototype.indexOf.call(
                    th.parentElement.children, th
                )

                const selector = `colgroup col:nth-child(${i + 1})`

                cols[0] = container.querySelector(
                    `.ant-table-header ${selector}`
                )

                cols[1] = container.querySelector(
                    `.ant-table-body ${selector}`
                )

                const {width} = th.getBoundingClientRect()
                w = width
            })

            resizer.addEventListener('pointermove', e => {
                if (! w) {
                    return
                }

                w = Math.max(MIN_WIDTH, w + e.movementX)

                for (const col of cols) {
                    col.style.width = `${w}px`
                }
            })

            resizer.addEventListener('pointerup', e => {
                resizer.releasePointerCapture(e.pointerId)
                table.setColumnConfig(columnKey, cfg => ({...cfg, width: w}))
                w = 0
            })

            th.appendChild(resizer)
        },

        []
    )
}
