// TODO: 横向

import {createContext, useContext, useRef} from 'react'
import {css} from '@emotion/react'

const DraggableListContext = createContext()

export const useDraggableListContext = () => useContext(DraggableListContext)

const cssDraggableList = css({
    position: 'relative',
})

export const useDraggableList = (refEl, onChange) => {
    const refInit = useRef({})
    const refItemList = useRef([])
    const refItemMap = useRef(new Map)

    const context = {
        deregisteritem: id => {
            refItemMap.current.delete(id)
        },

        registerItem: (id, el) => {
            refItemMap.current.set(id, {el, id})
        },

        move: (id, x, y, movementX, movementY) => {
            const init = refInit.current
            const list = refItemList.current
            const item = refItemMap.current.get(id)
            const i = list.indexOf(item)
            const top = init.top + y - init.y
            const bottom = top + item.height

            if (0 < movementY) {
                for (let j = i + 1; j < list.length; j += 1) {
                    const target = list[j]

                    if (target.top + target.height < bottom) {
                        list[j - 1] = target
                        target.top = item.top
                        target.el.style.top = `${target.top}px`
                        list[j] = item
                        item.top = target.top + target.height
                    }
                    else {
                        break
                    }
                }
            }
            else {
                for (let j = i - 1; - 1 < j; j -= 1) {
                    const target = list[j]

                    if (top < target.top) {
                        list[j] = item
                        item.top = target.top
                        list[j + 1] = target
                        target.top = item.top + item.height
                        target.el.style.top = `${target.top}px`
                    }
                    else {
                        break
                    }
                }
            }

            item.el.style.top = `${top}px`
        },

        moveStart: async (id, x, y) => {
            refInit.current = {y}
            const rectList = refEl.current.getBoundingClientRect()

            Object.assign(refEl.current.style, {
                width: `${rectList.width}px`,
                height: `${rectList.height}px`,
            })

            const rectItems = new Map

            for (const item of refItemMap.current.values()) {
                const els = item.el.parentElement.children
                item.index = Array.prototype.indexOf.call(els, item.el)
                refItemList.current[item.index] = item
                rectItems.set(item.id, item.el.getBoundingClientRect())
            }

            for (const item of refItemList.current) {
                const {height, width, top} = rectItems.get(item.id)
                Object.assign(item, {height, top: top - rectList.top})

                Object.assign(item.el.style, {
                    position: 'absolute',
                    top: `${top - rectList.top}px`,
                    width: `${width}px`,
                    height: `${height}px`,
                })

                if (id === item.id) {
                    refInit.current.top = item.top
                }
            }
        },

        moveEnd: (id) => {
            Object.assign(refEl.current.style, {
                width: null,
                height: null,
            })

            for (const item of refItemMap.current.values()) {
                Object.assign(item.el.style, {
                    position: null,
                    top: null,
                    width: null,
                    height: null,
                })
            }

            const newIndexes = refItemList.current.map(e => e.index)

            const isChanged = newIndexes.some(
                (newIndex, oldIndex) => newIndex !== oldIndex
            )

            if (isChanged) {
                onChange(newIndexes)
            }
        },
    }

    return context
}

export default function DraggableList({children, onChange, ...props}) {
    const refEl = useRef()
    const context = useDraggableList(refEl, onChange)

    return (
        <div
            ref={refEl}
            css={cssDraggableList}
            {...props}
        >
            <DraggableListContext.Provider value={context}>
                {children}
            </DraggableListContext.Provider>
        </div>
    )
}
