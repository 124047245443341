import {Button as AntdButton} from 'antd'
import stopPropagation from './stopPropagation.mjs'

const Button = ({onClick, ...props}) => {
    const handleClick = (e) => {
        stopPropagation(e)
        onClick(e)
    }

    const css = {
        padding: 0,

        '&[disabled]': {
            color: '#00000025',

            'svg path': {
                fill: '#00000025',
            },
        },
    }

    return (
        <AntdButton
            css={css}
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        />
    )
}

export default Button
