export default (table, columnKey) => {
    const onClick = () => {
        table.setColumnConfig(columnKey, {})
    }

    return {
        key: 'reset-column',
        label: '重置列设置',
        onClick: onClick,
    }
}
