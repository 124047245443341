import {message, Modal} from 'antd'
import SuspenseError from './SuspenseError.mjs'

export default async (
    fn,

    {
        action = '',

        error: {
            content: errorContent = (err) => {
                return action ? `${action}失败: ${err.message}` : err.message
            },

            duration: errorDuration = 5,
            show: showError = true,
        } = {},

        loading: {
            content: loadingContent = action ?
                `正在${action}，请稍候` : '请稍候',

            show: showLoading = true,
        } = {},

        success: {
            content: successContent = (result) => {
                return action ? `${action}成功` : '成功'
            },

            duration: succussDuration = 2,
            show: showSuccess = false,
        } = {},
    } = {},
) => {
    const hideLoading = showLoading ?
        message.loading(loadingContent, 0)
        :
        () => {}

    try {
        const result = await fn()
        hideLoading()

        if (showSuccess) {
            message.success(
                successContent(result),
                succussDuration
            )
        }

        return result
    }
    catch (err) {
        hideLoading()

        if (
            showError &&
            ! (err instanceof SuspenseError)
        ) {
            //message.error(errorContent(err), errorDuration);
            Modal.error({content: errorContent(err)})
        }

        throw err
    }
}
