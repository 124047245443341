import Input from '@/components/Form/InputNumber.jsx'
import {DEFAULT_CHANGE_DELAY} from '../config.mjs'

const InputNumber = ({
    changeDelay = DEFAULT_CHANGE_DELAY,
    ...props
}) => {
    return (
        <Input
            bordered={false}
            changeDelay={changeDelay}
            {...props}
        />
    )
}

export default InputNumber
