import {useEffect} from 'react'
import {css} from '@emotion/react'
import _ColorPicker, {useColorPicker} from 'react-best-gradient-color-picker'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import useCachedValue from '@/hooks/useCachedValue.mjs'
import cssInput from './cssInput.mjs'
import {useReadOnly} from './ReadOnly.mjs'

const cssPopoverContent = css({
    backgroundColor: '#fff',
    padding: 8,

    boxShadow: `
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05)
    `,
})

const PopoverContent = ({type, value, onChange, ...props}) => {
    const [cachedValue, setCachedValue] = useCachedValue(value)

    const handleChange = (value) => {
        setCachedValue(value)
        onChange(value)
    }

    const {setGradient} = useColorPicker(cachedValue, handleChange)

    useEffect(
        () => {
            if ('gradient' === type) {
                setGradient()
            }
        },

        []
    )

    return (
        <div
            css={cssPopoverContent}
            {...props}
        >
            <_ColorPicker
                hideColorTypeBtns
                value={cachedValue}
                onChange={handleChange}
            />
        </div>
    )
}

const cssPickerWrapper = css({
    ...cssInput,
    minWidth: '1.7em',
    minHeight: '1.7em',
})

const cssPicker = css({
    width: '100%',
    height: '100%',
})

const defaultOnChange = () => {}

export default function ColorPicker({
    disabled,
    readOnly,
    type = 'solid',
    value,
    onChange = defaultOnChange,
    ...props
}) {
    const gReadOnly = useReadOnly()

    const content = (
        <PopoverContent
            type={type}
            value={value}
            onChange={onChange}
        />
    )

    const handleClick = (e) => {
        if (disabled || readOnly || gReadOnly) {
            e.stopPropagation()
        }
    }

    const handleKeyPress = (e) => {
        if (disabled || readOnly || gReadOnly) {
            e.stopPropagation()
        }
    }

    // TODO: style for disabled

    return (
        <PopoverTrigger
            css={cssPickerWrapper}
            align="left"
            content={content}
            position="bottom"
            {...props}
        >
            <div
                css={cssPicker}
                style={{background: value}}
                onClick={handleClick}
                onKeyPress={handleKeyPress}
            ></div>
        </PopoverTrigger>
    )
}
