import {useRef} from 'react'
import {css} from '@emotion/react'
import {usePopover} from '@/components/Popover/index.mjs'

const cssPopoverTrigger = css({
    outline: 0,
})

export function PopoverTrigger({
    align = 'center',
    content = null,
    disabled = false,
    position = 'center',
    trigger = 'click',
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    ...props
}) {
    const popover = usePopover()
    const refEl = useRef()
    const refContent = useRef()

    const open = () => {
        const placement = {
            top: {
                'left-outside': 'top left',
                'left': 'top right',
                'center': 'top center',
                'right': 'top left',
                'right-outside': 'top right',
            },

            right: {
                'top-outside': 'top right',
                'top': 'bottom right',
                'center': 'center right',
                'bottom': 'top right',
                'bottom-outside': 'bottom right',
            },

            bottom: {
                'left-outside': 'bottom left',
                'left': 'bottom right',
                'center': 'bottom center',
                'right': 'bottom left',
                'right-outside': 'bottom right',
            },

            left: {
                'top-outside': 'top left',
                'top': 'bottom left',
                'center': 'center left',
                'bottom': 'top left',
                'bottom-outside': 'bottom left',
            },
        }[position][align]

        const {x, y} = (() => {
            const {
                bottom: b,
                left: l,
                right: r,
                top: t,
            } = refEl.current.getBoundingClientRect()

            const getX = (align) => ({
                'left-outside': l,
                'left': l,
                'center': (l + r) / 2,
                'right': r,
                'right-outside': r,
            }[align])

            const x = {
                top: getX,
                right: () => r,
                bottom: getX,
                left: () => l,
            }[position](align)

            const getY = (align) => ({
                'top-outside': t,
                'top': t,
                'center': (t + b) / 2,
                'bottom': b,
                'bottom-outside': b,
            }[align])

            const y = {
                top: () => t,
                right: getY,
                bottom: () => b,
                left: getY,
            }[position](align)

            return {x, y}
        })()

        popover.open(
            <div ref={refContent} >
                {content}
            </div>,

            {
                placement,
                trigger: refEl.current,
                x,
                y,
            }
        )

        refEl.current?.focus()
    }

    const close = () => {
        popover.open(null)
    }

    //const handleBlur = (e) => {
        //if (popover.key) {
            //e.stopPropagation()
        //}
    //}

    const handleMouseDown = disabled || 'click' !== trigger ?
        onMouseDown
        :
        e => {
            open()
            onMouseDown?.(e)
        }

    //const handleFocus = (e) => {
        //if (popover.key) {
            //e.stopPropagation()
        //}
    //}

    //const handleKeyPress = (e) => {
        //if (disabled) {
            //return
        //}

        //if (/^( |Enter)$/.test(e.key)) {
            //open()
        //}
    //}

    const [
        handleMouseEnter,
        handleMouseLeave,
    ] = disabled || 'hover' !== trigger ?
        [onMouseEnter, onMouseLeave]
        :
        [
            e => {
                (() => {
                    let el = e.relatedTarget

                    while (el) {
                        if (el === refContent.current) {
                            return
                        }
                        else {
                            el = el.parentElement
                        }
                    }

                    open()
                })()

                onMouseEnter?.(e)
            },

            e => {
                (() => {
                    let el = e.relatedTarget

                    while (el) {
                        if (el === refContent.current) {
                            return
                        }
                        else {
                            el = el.parentElement
                        }
                    }

                    close()
                })()

                onMouseLeave?.(e)
            }
        ]

    return (
        <div
            ref={refEl}
            css={cssPopoverTrigger}
            tabIndex="0"
            //onBlur={handleBlur}
            //onFocus={handleFocus}
            //onKeyPress={handleKeyPress}
            onMouseDown={handleMouseDown}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
        >
        </div>
    )
}
