import {useState} from 'react'
import {Button, Modal} from 'antd'
import {useTableContext} from '../TableContext.jsx'

const TopButton = ({
    minRows = 0,
    afterClick,
    onClick,
    ...props
}) => {
    const table = useTableContext()
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = async () => {
        if (table.rowSelection?.selectedRowKeys.length < minRows) {
            Modal.info({content: `请至少选择 ${minRows} 条数据`})
            return
        }

        try {
            setIsLoading(true)
            await onClick()
            afterClick?.()
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <Button
            ghost
            loading={isLoading}
            size="small"
            type="primary"
            onClick={handleClick}
            {...props}
        />
    )
}

export default TopButton
