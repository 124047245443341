import {useRef} from 'react'
import {getScrollbarSize} from '@/script/scrollbar.mjs'

const isOnScrollbar = (e) => {
    const {width: sw, height: sh} = getScrollbarSize(e.currentTarget)
    const {bottom, right} = e.currentTarget.getBoundingClientRect()

    return (
        right - sw <= e.clientX ||
        bottom - sh <= e.clientY
    )
}

export default (handlers) => {
    const refDown = useRef(null)
    const refMove = useRef(null)
    const refTarget = useRef(null)

    const extendEvent = (e) => {
        const {
            left,
            scrollLeft,
            scrollTop,
            top,
        } = refTarget.current

        const relativeX = e.clientX + scrollLeft - left
        const relativeY = e.clientY + scrollTop - top
        Object.assign(e, {relativeX, relativeY})

        if (refDown.current) {
            const {
                clientX: initialClientX,
                clientY: initialClientY,
                relativeX: initialX,
                relativeY: initialY,
            } = refDown.current

            Object.assign(e, {
                initialClientX,
                initialClientY,
                initialX,
                initialY,
            })
        }
        else {
            Object.assign(e, {
                initialClientX: e.clientX,
                initialClientY: e.clientY,
                initialX: relativeX,
                initialY: relativeY,
            })
        }

        return e
    }

    const onClick = handlers.onClick ?
        e => {
            const ee = extendEvent(e)
            handlers.onClick(ee)
        }
        :
        undefined

    const onDoubleClick = handlers.onDoubleClick ?
        e => {
            const ee = extendEvent(e)
            handlers.onDoubleClick(ee)
        }
        :
        undefined

    const onPointerDown = (e) => {
        refMove.current = null

        if (isOnScrollbar(e)) {
            return
        }

        const {scrollLeft, scrollTop} = e.currentTarget
        const {left, top} = e.currentTarget.getBoundingClientRect()

        refTarget.current = {
            left,
            scrollLeft,
            scrollTop,
            top,
        }

        const ee = extendEvent(e)
        handlers.onPointerDown?.(ee)
        refDown.current = ee
    }

    const onPointerMove = (e) => {
        if (refDown.current) {
            const ee = extendEvent(e)

            if (! refMove.current) {
                e.currentTarget.setPointerCapture(e.pointerId)
                handlers.onSwipeStart?.(ee)
            }

            handlers.onSwipeMove?.(ee)
        }

        refMove.current = e
        handlers.onPointerMove?.(e)
    }

    const onPointerUp = (e) => {
        if (! refDown.current) {
            return
        }

        const ee = extendEvent(e)
        ee.buttons = refDown.current.buttons

        if (refMove.current) {
            ee.buttons = refMove.current.buttons
            handlers.onSwipeEnd?.(ee)
            e.currentTarget.releasePointerCapture(e.pointerId)
        }

        handlers.onPointerUp?.(ee)
        refDown.current = null
    }

    return {
        onClick,
        onDoubleClick,
        onPointerDown,
        onPointerMove,
        onPointerUp,
    }
}
