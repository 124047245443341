import {useState} from 'react'
import {Input, Select} from 'antd'
import CheckboxFilter from '../CheckboxFilter.jsx'
import FilterDropdown from '../FilterDropdown.jsx'
import InputRange from './InputRange.jsx'

const sort = {
    asc: (a = -Infinity, b = -Infinity) => a - b,
    desc: (a = -Infinity, b = -Infinity) => b - a,
}

const InputFilterRange = ({
    mode,
    range,
    setMode,
    setRange,
}) => {
    const options = [
        {label: '之间', value: 'between'},
        {label: '之外', value: 'except'},
    ]

    const css = {
        display: 'flex !important',
    }

    return (
        <Input.Group
            css={css}
            compact
        >
            <Select
                size="small"
                options={options}
                value={mode}
                onChange={setMode}
            />

            <InputRange
                size="small"
                value={range}
                onChange={setRange}
            />
        </Input.Group>
    )
}

const defaultGetNumber = (a) => a || 0 === a ? Number(a) : ''

const FilterNumber = ({
    confirm,
    getNumber = defaultGetNumber,
    getValues,
    setFilter,
    setSort,
}) => {
    const [keyword, setKeyword] = useState('')
    const [mode, setMode] = useState('between')
    const [order, setOrder] = useState('')
    const [range, setRange] = useState([])
    const [selectedNumbers, setSelectedNumbers] = useState([])
    const [min = -Infinity, max = Infinity] = range

    const filterNumber = {
        between: (value = 0) => min <= value && value <= max,
        except: (value = 0) => value < min || max < value,
    }[mode]

    const numbers = getValues().map(getNumber)
    const numbersForCheck = numbers.filter(filterNumber)

    if (order) {
        numbersForCheck.sort((a, b) => sort[order](a, b))
    }

    const handleConfirm = () => {
        if (
            0 < selectedNumbers.length &&
            selectedNumbers.length < numbers.length
        ) {
            setFilter(
                (value) => {
                    const number = getNumber(value)
                    return selectedNumbers.includes(number)
                }
            )
        }
        else {
            setFilter(null)
        }

        if (order) {
            setSort((a, b) => {
                const numA = getNumber(a)
                const numB = getNumber(b)
                return sort[order](numA, numB)
            })
        }
        else {
            setSort(null)
        }

        confirm()
    }

    const handleReset = () => {
        setKeyword('')
        setMode('between')
        setOrder('')
        setRange([])
        setSelectedNumbers([])
    }

    return (
        <FilterDropdown
            order={order}
            onChangeOrder={setOrder}
            onConfirm={handleConfirm}
            onReset={handleReset}
        >
            <InputFilterRange
                mode={mode}
                range={range}
                setMode={setMode}
                setRange={setRange}
            />

            <CheckboxFilter
                keyword={keyword}
                value={selectedNumbers}
                values={numbersForCheck}
                onChange={setSelectedNumbers}
                onChangeKeyword={setKeyword}
            />
        </FilterDropdown>
    )
}

export default FilterNumber
