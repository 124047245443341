import {join} from 'hopedove-dom/url'
import ServerError from '@/script/ServerError.mjs'
import SuspenseError from '@/script/SuspenseError.mjs'
import httpRequest from '@/script/http.mjs'
import {setToken, token} from './token.mjs'
import {logout} from './user.mjs'

const normalizeUrl = (url) => {
    if (! url) {
        return import.meta.env.VITE_HTTP_BASE_URL
    }

    if (! url.startsWith('/')) {
        return url
    }

    return join(import.meta.env.VITE_HTTP_BASE_URL, url)
}

let isRefreshingToken = false
let promiseRefreshToken = Promise.resolve()

const request = async ({headers, url, ...options}) => {
    const rsp = await httpRequest({
        headers,
        url: normalizeUrl(url),
        ...options,
    })

    if (rsp.code) {
        const {code, message, responseBody} = rsp

        if ('00000000' !== code) {
            throw new ServerError(code, message)
        }

        return responseBody
    }
    else {
        return rsp
    }
}

const withToken = async fn => {
    if (isRefreshingToken) {
        await promiseRefreshToken
    }

    const lastToken = token?.token

    try {
        return await fn(lastToken)
    }
    catch (err) {
        // 令牌过期
        if ('00000010' === err.code) {
            // 已登出
            if (! token) {
                throw new SuspenseError(err.message)
            }
            // 令牌已被刷新
            else if (lastToken !== token.token) {
                // 重试
                return withToken(fn)
            }

            if (! isRefreshingToken) {
                refreshToken()
            }

            // 重试
            return withToken(fn)
        }
        // 
        else if ('00000011' === err.code) {
            throw new SuspenseError(err.message)
        }
        // 单点登录
        else if ('00000090' === err.code) {
            // 如果有多个请求，只提示一次

            const shouldThrow = Boolean(token)
            logout()

            if (shouldThrow) {
                throw err
            }
            else {
                throw new SuspenseError(err.message)
            }
        }
        else {
            throw err
        }
    }
}

const requestWithToken = ({headers, ...options}) => withToken(
    async token => request({
        headers: {...headers, __TOKEN__: token},
        ...options
    })
)

const doRefreshToken = async ({token, refreshToken}) => request({
    headers: {__TOKEN__: token},
    method: 'POST',
    url: '/login/token/refresh',
    payload: {token, refreshToken},
})

const refreshToken = async () => {
    const {promise, reject, resolve} = Promise.withResolvers()

    try {
        isRefreshingToken = true
        promiseRefreshToken = promise
        const newToken = await doRefreshToken(token)
        setToken(newToken)
        resolve(newToken)
    }
    catch (err) {
        logout()
        reject(new SuspenseError(err.message))
    }
    finally {
        isRefreshingToken = false
    }
}

const upload = (payload = {}, options = {}) => requestWithToken({
    headers: {
        ...options.headers,
        'Content-Type': 'multipart/form-data',
    },

    method: 'POST',

    payload: {
        contentType: payload.file?.type,
        ...payload,

        pathName: payload.pathName?.startsWith('/') ?
        payload.pathName : `/${payload.pathName}`,
    },

    url: '/files',
    ...options,
})

export default {
    upload,

    ...Object.fromEntries(
        ['delete', 'get', 'head', 'post', 'put'].map(
            method => [
                method,

                (url, payload, options = {}) => requestWithToken({
                    ...options,
                    method: method.toUpperCase(),
                    payload,
                    url,
                })
            ]
        )
    ),
}
