export default (table, columnKey) => {
    const onClick = () => {
        table.setColumnConfig(columnKey, ({width, ...cfg}) => cfg)
    }

    return {
        key: 'reset-column-width',
        label: '重置列宽',
        onClick: onClick,
    }
}
