import {useRef, useState} from 'react'

export default (init, controlledState, onChange) => {
    const [state, setState] = useState(init)
    const refArgs = useRef()
    refArgs.current = [controlledState, onChange]

    // 受控
    if (
        void 0 !== controlledState &&
        'function' === typeof onChange
    ) {
        return [
            controlledState,

            x => {
                const [state, setState] = refArgs.current
                const newState = 'function' === typeof x ? x(state) : x
                setState(newState)
            }
        ]
    }
    // 非受控
    else {
        return [state, setState]
    }
}
