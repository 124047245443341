import {css} from '@emotion/react'
import {SettingOutlined} from '@ant-design/icons'
import {usePopover} from '@/components/Popover/index.mjs'
import {useTableContext} from '../TableContext.jsx'
import ContextMenu from './ContextMenu.jsx'

const cssButtonConfig = css({
    '&:hover': {
        color: '#1890ff',
        cursor: 'pointer',
    }
})

export default function ButtonConfig(props) {
    const popover = usePopover()
    const table = useTableContext()

    const handleClick = ({clientX: x, clientY: y}) => {
        popover.open(
            <ContextMenu table={table} />,
            {placement: 'bottom right', x, y}
        )
    }

    return (
        <SettingOutlined
            css={cssButtonConfig}
            title="设置"
            onClick={handleClick}
            {...props}
        />
    )
}
