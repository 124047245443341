import {useEffect, useState} from 'react'
import {sharedEventBus} from '@/script/sharedEventBus.mjs'

const KEY = 'token'

const load = () => {
    const item = window.localStorage.getItem(KEY)

    if (! item) {
        return null
    }

    try {
        return JSON.parse(item)
    }
    catch (err) {
        console.err(err)
        return null
    }
}

const save = token => {
    if (token) {
        const item = JSON.stringify(token)
        window.localStorage.setItem(KEY, item)
    }
    else {
        window.localStorage.removeItem(KEY)
    }
}

export let token = load()

sharedEventBus.subscribe(KEY, e => token = e.data)

export const setToken = newToken => {
    if (newToken === token) {
        return
    }

    token = newToken
    save(token)
    sharedEventBus.publish(KEY, token)
}

export const useToken = () => {
    const [, setFlag] = useState(false)

    useEffect(
        () => {
            const handleToken = () => setFlag(f => ! f)
            sharedEventBus.subscribe(KEY, handleToken)
            return () => sharedEventBus.unsubscribe(KEY, handleToken)
        },

        []
    )

    return token
}
