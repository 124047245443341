export default (table, columnKey) => {
    const onClick = () => {
        table.setColumnConfig(
            columnKey,
            ({backgroundColor, color, ...cfg}) => cfg
        )
    }

    return {
        key: 'reset-column-colors',
        label: '重置颜色',
        onClick: onClick,
    }
}
