import {PopoverTrigger} from '@/components/Popover/index.mjs'
import ContextMenu, {ContextMenuContext, useContextMenuContext} from './ContextMenu.jsx'
import ContextMenuItem from './ContextMenuItem.jsx'

export default function ContextSubMenu({disabled, items, ...props}) {
    const supMenu = useContextMenuContext()

    const content = (
        <ContextMenuContext.Provider value={supMenu}>
            <ContextMenu items={items} />
        </ContextMenuContext.Provider>
    )

    return (
        <PopoverTrigger
            align="top"
            content={content}
            disabled={disabled}
            position="right"
            trigger="hover"
        >
            <ContextMenuItem
                disabled={disabled}
                suffix="▸"
                onClick={e => e.preventDefault()}
                {...props}
            />
        </PopoverTrigger>
    )
}
