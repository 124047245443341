import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import useMenuItemAlignContent from './useMenuItemAlignContent.jsx'
import useMenuItemColumnBgColor from './useMenuItemColumnBgColor.jsx'
import useMenuItemColumnColor from './useMenuItemColumnColor.jsx'
import useMenuItemFitContent from './useMenuItemFitContent.jsx'
import useMenuItemFixColumn from './useMenuItemFixColumn.jsx'
import useMenuItemHideColumn from './useMenuItemHideColumn.jsx'
import useMenuItemResetColumn from './useMenuItemResetColumn.jsx'
import useMenuItemResetColumnColors from './useMenuItemResetColumnColors.jsx'
import useMenuItemResetColumnWidth from './useMenuItemResetColumnWidth.jsx'

export default ({table, columnKey, ...props}) => {
    const items = [
        ...useMenuItemFixColumn(table, columnKey),
        {divider: true},
        ...useMenuItemAlignContent(table, columnKey),
        {divider: true},
        useMenuItemColumnColor(table, columnKey),
        useMenuItemColumnBgColor(table, columnKey),
        useMenuItemResetColumnColors(table, columnKey),
        {divider: true},
        useMenuItemFitContent(table, columnKey),
        useMenuItemResetColumnWidth(table, columnKey),
        {divider: true},
        useMenuItemHideColumn(table, columnKey),
        useMenuItemResetColumn(table, columnKey),
    ]

    return (
        <ContextMenu
            items={items}
            {...props}
        />
    )
}
