import {
    cloneElement,
    createContext,
    useContext,
    useLayoutEffect,
    useRef,
} from 'react'

import {css} from '@emotion/react'
import {usePopover} from '@/components/Popover/index.mjs'
import getBoundingClientRects from '@/script/getBoundingClientRects.mjs'
import Divider from './ContextMenuDivider.jsx'
import MenuItem from './ContextMenuItem.jsx'
import SubMenu from './ContextSubMenu.jsx'

export const ContextMenuContext = createContext()
export const useContextMenuContext = () => useContext(ContextMenuContext)

export const cssContextMenu = css({
    maxWidth: '100vw',
    maxHeight: '100vh',
    backgroundColor: '#fff',
    padding: '2px 0',
    overflow: 'auto',

    boxShadow: `
        rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px
    `,

    userSelect: 'none',
})

export default function ContextMenu({items, ...props}) {
    const popover = usePopover()
    const supMenu = useContextMenuContext()
    const refEl = useRef()

    useLayoutEffect(
        () => {
            const el = refEl.current

            const align = async (selector) => {
                const els = el.querySelectorAll(selector)
                const rects = await getBoundingClientRects(els)

                const maxWidth = rects.reduce(
                    (w, {width}) => Math.max(w, width),
                    0
                )

                for (const el of els) {
                    el.style.width = `${maxWidth}px`
                }
            }

            align('.hd-context-menu__item__prefix')
        },

        []
    )

    const context = supMenu ?? {
        close: () => popover.close()
    }

    const handleClick = e => {
        if (! e.defaultPrevented) {
            context.close()
        }
    }

    const children = items.map(
        ({children, divider, element, label, key = label, ...props}, i) => {
            if (divider) {
                return <Divider key={`DIVIDER-${i}`} />
            }
            else if (children) {
                return (
                    <SubMenu
                        key={key}
                        items={children}
                        label={label}
                        {...props}
                    />
                )
            }
            else if (element) {
                return cloneElement(element, {key, ...props})
            }
            else {
                return (
                    <MenuItem
                        key={key}
                        label={label}
                        {...props}
                    />
                )
            }
        }
    )

    return (
        <div
            ref={refEl}
            css={cssContextMenu}
            onClick={handleClick}
            {...props}
        >
            <ContextMenuContext.Provider value={context} >
                {children}
            </ContextMenuContext.Provider>
        </div>
    )
}
