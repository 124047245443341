import {css} from '@emotion/react'
import usePointer from '@/hooks/usePointer.mjs'
import {useDraggableListItemContext} from './DraggableListItem.jsx'

const cssDraggableListTrigger = css({
    cursor: 'pointer',
})

export const useDraggableListTrigger = () => {
    const li = useDraggableListItemContext()

    return usePointer({
        onClick: e => {
            e.stopPropagation()
        },

        onDoubleClick: e => {
            e.stopPropagation()
        },

        onPointerDown: e => {
            e.stopPropagation()
            li.focus()
        },

        onPointerUp: e => {
            e.stopPropagation()
            li.blur()
        },

        onSwipeEnd: () => {
            li.moveEnd()
        },

        onSwipeMove: e => {
            e.stopPropagation()
            li.move(e.clientX, e.clientY, e.movementX, e.movementY)
        },

        onSwipeStart: e => {
            li.moveStart(e.clientX, e.clientY)
        },
    })
}

export default function DraggableListTrigger(props) {
    const pointerEventHandlers = useDraggableListTrigger()

    return (
        <div
            css={cssDraggableListTrigger}
            {...pointerEventHandlers}
            {...props}
        >
        </div>
    )
}
