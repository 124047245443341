import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import useMenuItemColumnVisibility from './useMenuItemColumnVisibility.jsx'
import useMenuItemResetTable from './useMenuItemResetTable.jsx'
import useMenuItemRowStripe from './useMenuItemRowStripe.jsx'

export default ({table, ...props}) => {
    const items = [
        useMenuItemRowStripe(table),
        useMenuItemColumnVisibility(table),
        {divider: true},
        useMenuItemResetTable(table),
    ]

    return (
        <ContextMenu
            items={items}
            {...props}
        />
    )
}
