import {css} from '@emotion/react'
import Input from './InputText.jsx'

const cssInputNumber = css({
    textAlign: 'right',
})

const InputNumber = ({
    int = false,
    max = Infinity,
    min = 0,
    precision,
    scale,
    ...props
}) => {
    const toNumber = (s) => {
        const parse = int ? Number.parseInt : Number.parseFloat
        return parse(s)
    }

    const parse = (s) => {
        const n = toNumber(s)
        return Number.isNaN(n) ? null : n
    }

    const test = (str) => {
        const p = (() => {
            const p = parseInt(precision)

            if (! p) {
                return Number.MAX_SAFE_INTEGER
            }

            if (p < 1) {
                return Number.MAX_SAFE_INTEGER
            }

            return p
        })()

        const s = (() => {
            const s = parseInt(scale)

            if (! s) {
                return Number.MAX_SAFE_INTEGER
            }

            if (s < 1) {
                return Number.MAX_SAFE_INTEGER
            }

            if (p <= s) {
                return p - 1
            }

            return s
        })()

        if (null === min || min < 0) {
            if (int) {
                const re = new RegExp(`^-?\\d{0,${p}}$`)
                return re.test(str)
            }
            else {
                const match = /^-?(\d*)\.?(\d*)$/.exec(str)

                if (match) {
                    const [, g1, g2] = match

                    return (
                        g2.length <= s &&
                        g1.length + g2.length <= p
                    )
                }
                else {
                    return false
                }
            }
        }
        else {
            if (int) {
                const re = new RegExp(`^\\d{1,${p}}$`)
                return re.test(str)
            }
            else {
                const match = /^(\d+)\.?(\d*)$/.exec(str)

                if (match) {
                    const [, g1, g2] = match

                    return (
                        g2.length <= s &&
                        g1.length + g2.length <= p
                    )
                }
                else {
                    return false
                }
            }
        }
    }

    const transform = (s) => {
        const n = toNumber(s)

        // 比最小值更小时返回最小值
        if (n < (min ?? -Infinity)) {
            return min
        }
        // 比最大值更大时返回最大值
        else if ((max ?? Infinity) < n) {
            return max
        }
        else {
            return s
        }
    }

    return (
        <Input
            css={cssInputNumber}
            parse={parse}
            test={test}
            transform={transform}
            {...props}
        />
    )
}

export default InputNumber
