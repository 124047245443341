import {useRef} from 'react'
import {usePopover} from '@/components/Popover/index.mjs'
import {useTableContext} from '../TableContext.jsx'
import ContextMenu from './ContextMenu.jsx'
import useResizer from './useResizer.mjs'

export default function ColumnTitle({columnKey, ...props}) {
    const popover = usePopover()
    const table = useTableContext()
    const refEl = useRef()
    useResizer(refEl, table, columnKey)

    const handleContextMenu = e => {
        e.preventDefault()
        const {clientX: x, clientY: y} = e

        popover.open(
            <ContextMenu
                columnKey={columnKey}
                table={table}
            />,

            {placement: 'bottom right', x, y}
        )
    }

    return (
        <div
            ref={refEl}
            data-column-key={columnKey}
            onContextMenu={handleContextMenu}
            {...props}
        >
        </div>
    )
}
