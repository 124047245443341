import useControlledState from '@/hooks/useControlledState.mjs'
import useObserveState from '@/hooks/useObserveState.mjs'

const normalizeColumnsConfig = (columns, config = []) => {
    const normalizedConfig = []

    const columnIds = new Set(
        columns.map(e => e.key ?? e.dataIndex)
    )

    const configIds = new Set()

    for (const c of config) {
        if (columnIds.has(c.id)) {
            configIds.add(c.id)
            normalizedConfig.push(c)
        }
    }

    for (const c of columns) {
        const id = c.key ?? c.dataIndex

        if (! configIds.has(id)) {
            normalizedConfig.push({id})
        }
    }

    return normalizedConfig
}

const normalizeConfig = (columns, config) => {
    if (config) {
        return {
            columns: normalizeColumnsConfig(columns, config.columns),
            pagination: config.pagination ?? {},
            striped: config.striped ?? false,
        }
    }
}

export default (columns, controlledConfig, onChangeConfig) => {
    const [_config, _setConfig] = useControlledState(
        {},
        controlledConfig,
        onChangeConfig
    )

    const config = normalizeConfig(columns, _config)
    const [setConfig, useConfig] = useObserveState(config, _setConfig)

    const setColumnConfig = (id, x) => {
        setConfig(config => {
            const i = config.columns.findIndex(e => id === e.id)

            if (- 1 < i) {
                const column = config.columns[i]
                const cfg = 'function' === typeof x ? x(column) : x
                const columns = config.columns.toSpliced(i, 1, {...cfg, id})
                return {...config, columns}
            }
            else {
                throw new Error(`未找到列设置: ${id}`)
            }
        })
    }

    const useColumnConfig = id => {
        const {columns} = useConfig()
        return columns.find(e => id === e.id)
    }

    return {
        config,
        setConfig,
        setColumnConfig,
        useColumnConfig,
        useConfig,
    }
}
