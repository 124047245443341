import {Table as AntdTable} from 'antd'
import VirtualTable from './VirtualTable.jsx'

const TableContent = ({
    rowHeight,
    rowSelection,
    ...props
}) => {
    const cssFlex = {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
    }

    const css = {
        ...cssFlex,

        '& .ant-spin-nested-loading': {
            ...cssFlex,
        },

        '& .ant-spin-container': {
            ...cssFlex,
        },

        '& .ant-table-container': {
            ...cssFlex,
            borderBottom: '1px solid #f0f0f0',
            borderRight: '1px solid #f0f0f0',

            borderTop: false === props.showHeader ?
                '1px solid #f0f0f0' : 'unset',
        },

        '& .ant-table': {
            ...cssFlex,
        },

        '& .ant-table-header': {
            flexShrink: 0,

            'tr': {
                backgroundColor: '#fafafa',
            },
        },

        // TODO 解决表头字体不加粗问题
        '& .ant-table-thead': {
            flexShrink: 0,

            'th': {
                fontWeight: 'bold'
            },
        },

        '& .ant-table-body': {
            flexGrow: 1,
            maxHeight: 'unset !important',
            overflow: 'auto !important',
        },

        '.ant-table-row, .ant-table-summary>tr': {
            backgroundColor: '#fff',
        },

        '.ant-table-cell-fix-left, .ant-table-cell-fix-right': {
            backgroundColor: 'inherit',
        },

        '& .ant-table .ant-table-header .ant-table-cell': {
            textAlign: 'center !important',
            padding: '4px 8px',
        },

        '& .ant-table .ant-table-tbody .ant-table-cell': {
            padding: '4px 8px',
        },

        '& .ant-table .ant-table-summary .ant-table-cell': {
            padding: '4px 8px',
        },

        '& .ant-table-selection-column': {
            backgroundColor: '#fafafa !important',
        },

        '& .ant-table-pagination.ant-pagination': {
            margin: '12px 0 1px 0 !important'
        },

        // 修复左固定最后一列同时设置了 ellipsis 后列头的过滤图标显示不全
        'th.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content': {
            overflow: 'visible',
        },

        '.ant-table-cell-ellipsis .ant-table-column-title': {
            whiteSpace: 'normal',
        }
    }

    const tableProps = {
        css,
        bordered: true,
        scroll: { x: 0, y: 0 },
        ...props,
    }

    if (rowHeight) {
        return (
            <VirtualTable
                {...tableProps}
                rowHeight={rowHeight}
            />
        )
    }
    else {
        return (
            <AntdTable
                {...tableProps}
                rowSelection={rowSelection}
            />
        )
    }
}

export default TableContent
