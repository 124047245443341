import {Checkbox} from 'antd'

export default (table, columnKey) => {
    const {columns} = table.useConfig()
    const {fixed} = columns.find(e => e.id === columnKey)
    const prefixLeft = <Checkbox checked={'left' === fixed} />
    const prefixRight = <Checkbox checked={'right' === fixed} />

    const handleClickLeft = () => {
        table.setConfig(cfg => {
            const i = cfg.columns.findIndex(e => e.id === columnKey)

            if ('left' === fixed) {
                const column = {...cfg.columns[i], fixed: ''}

                for (let j = i + 1; j < cfg.columns.length; j += 1) {
                    if ('left' === cfg.columns[j].fixed) {
                        continue
                    }

                    const columns = [
                        ...cfg.columns.slice(0, i),
                        ...cfg.columns.slice(i + 1, j),
                        column,
                        ...cfg.columns.slice(j),
                    ]

                    return {...cfg, columns}
                }

                // 最后一列或者之后的列都不是左固定

                const columns = cfg.columns.with(i, column)
                return {...cfg, columns}
            }
            else {
                const column = {...cfg.columns[i], fixed: 'left'}

                for (let j = 0; j < i; j += 1) {
                    if ('left' === cfg.columns[j].fixed) {
                        continue
                    }

                    const columns = [
                        ...cfg.columns.slice(0, j),
                        column,
                        ...cfg.columns.slice(j, i),
                        ...cfg.columns.slice(i + 1),
                    ]

                    return {...cfg, columns}
                }

                // 第一列或者之前的列都是左固定

                const columns = cfg.columns.with(i, column)
                return {...cfg, columns}
            }
        })
    }

    const handleClickRight = () => {
        table.setConfig(cfg => {
            const i = cfg.columns.findIndex(e => e.id === columnKey)

            if ('right' === fixed) {
                const column = {...cfg.columns[i], fixed: ''}

                for (let j = i - 1; - 1 < j; j -= 1) {
                    if ('right' === cfg.columns[j].fixed) {
                        continue
                    }

                    const columns = [
                        ...cfg.columns.slice(0, j + 1),
                        column,
                        ...cfg.columns.slice(j + 1, i),
                        ...cfg.columns.slice(i + 1),
                    ]

                    return {...cfg, columns}
                }

                // 第一列或者之前的列都不是右固定

                const columns = cfg.columns.with(i, column)
                return {...cfg, columns}
            }
            else {
                const column = {...cfg.columns[i], fixed: 'right'}

                for (let j = cfg.columns.length - 1; i < j; j -= 1) {
                    if ('right' === cfg.columns[j].fixed) {
                        continue
                    }

                    const columns = [
                        ...cfg.columns.slice(0, i),
                        ...cfg.columns.slice(i + 1, j + 1),
                        column,
                        ...cfg.columns.slice(j + 1),
                    ]

                    return {...cfg, columns}
                }

                // 最后一列或者之后的列都是右固定

                const columns = cfg.columns.with(i, column)
                return {...cfg, columns}
            }
        })
    }

    return [
        {
            key: 'fix-column-left',
            label: '左固定',
            prefix: prefixLeft,
            onClick: handleClickLeft,
        },

        {
            key: 'fix-column-right',
            label: '右固定',
            prefix: prefixRight,
            onClick: handleClickRight,
        },
    ]
}
