import {Checkbox} from 'antd'
import {MenuOutlined} from '@ant-design/icons'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import {cssContextMenu} from '@/components/ContextMenu/ContextMenu.jsx'
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem.jsx'
import DraggableList from '@/components/DraggableList/DraggableList.jsx'
import DraggableListItem from '@/components/DraggableList/DraggableListItem.jsx'
import DraggableListTrigger from '@/components/DraggableList/DraggableListTrigger'

const ColumnVisibilityListItem = ({label, isHidden, onChange, ...props}) => {
    const onClick = e => {
        e.preventDefault()
        onChange(! isHidden)
    }

    const prefix = <Checkbox checked={! isHidden} />

    const suffix = (
        <DraggableListTrigger>
            <MenuOutlined />
        </DraggableListTrigger>
    )

    return (
        <DraggableListItem {...props}>
            <ContextMenuItem
                prefix={prefix}
                label={label}
                suffix={suffix}
                onClick={onClick}
            />
        </DraggableListItem>
    )
}

const ColumnVisibilityList = ({table, ...props}) => {
    const {columns} = table.useConfig()

    const columnsMap = new Map(
        table.columns.map(e => [e.key ?? e.dataIndex, e])
    )

    const children = columns.map(({id, hidden}) => {
        const column = columnsMap.get(id)
        const {title, titleText} = column

        const onChange = hidden => {
            table.setColumnConfig(id, cfg => ({...cfg, hidden}))
        }

        return (
            <ColumnVisibilityListItem
                key={id}
                isHidden={hidden}
                label={titleText ?? title}
                onChange={onChange}
            />
        )
    })

    const handleChange = indexes => {
        table.setConfig(cfg => ({
            ...cfg,
            columns: indexes.map(i => cfg.columns[i]),
        }))
    }

    return (
        <DraggableList
            css={cssContextMenu}
            onChange={handleChange}
            {...props}
        >
            {children}
        </DraggableList>
    )
}

const MenuItemColumnVisibility = ({disabled, table, ...props}) => {
    return (
        <PopoverTrigger
            align="top"
            content={<ColumnVisibilityList table={table} />}
            disabled={disabled}
            position="right"
            trigger="hover"
        >
            <ContextMenuItem
                disabled={disabled}
                label="隐藏/显示列"
                suffix="▸"
                onClick={e => e.preventDefault()}
                {...props}
            />
        </PopoverTrigger>
    )
}

export default (table) => {
    return {
        element: <MenuItemColumnVisibility table={table} />,
        disabled: 0 === table.config.columns.length,
        key: 'column-visibility',
    }
}
