export default (table, columnKey) => {
    const onClick = () => {
        table.setColumnConfig(columnKey, cfg => ({...cfg, hidden: true}))
    }

    return {
        key: 'hide-column',
        label: '隐藏列',
        onClick: onClick,
    }
}
