import {css} from '@emotion/react'

const cssContextMenuDivider = css({
    boxSizing: 'content-box',
    height: 1,
    backgroundColor: '#ececec',
    backgroundClip: 'content-box',
    padding: '2px 12px',

    '&:first-child': {
        display: 'none',
    },

    '&:last-child': {
        display: 'none',
    },

    '&+&': {
        display: 'none',
    },
})

export default function ContextMenuDivider(props) {
    return (
        <div
            css={cssContextMenuDivider}
            {...props}
        >
        </div>
    )
}
