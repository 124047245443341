import {useEffect} from 'react'

import {
    publish,
    subscribe,
    subscribeOnce,
    unsubscribe,
} from '@/script/event.mjs'

export const eventBus = {
    publish(target, eventType, data) {
        publish(this, eventType, {data, eventType, target})
    },

    subscribe(...args) {subscribe(this, ...args)},
    subscribeOnce(...args) {subscribeOnce(this, ...args)},
    unsubscribe(...args) {unsubscribe(this, ...args)},
}

export const useEventBus = (eventType, callback) => {
    useEffect(
        () => {
            eventBus.subscribe(eventType, callback)
            return () => eventBus.unsubscribe(eventType, callback)
        },

        [eventType, callback]
    )
}
