export default {
    '.ant-input[readonly]': {
        '&+.ant-input-suffix .ant-input-clear-icon, &+.ant-input-clear-icon': {
            display: 'none',
        },
    },

    '.ant-input-clear-icon': {
        opacity: 0,
    },

    '&:hover .ant-input-clear-icon': {
        opacity: 1,
    }
}
