const sources2subscribers = new WeakMap

export const publish = (source, eventType, detail) => {
    if (! (source && eventType)) {
        return
    }

    const subscribers = sources2subscribers.get(source)

    if (! subscribers) {
        return
    }

    const callbacks = subscribers.get(eventType)

    if (! callbacks) {
        return
    }

    for (const callback of [...callbacks]) {
        callback(detail)
    }
}

export const subscribe = (source, eventType, callback) => {
    if (! (source && eventType && callback)) {
        return
    }

    let subscribers = sources2subscribers.get(source)

    if (! subscribers) {
        subscribers = new Map
        sources2subscribers.set(source, subscribers)
    }

    let callbacks = subscribers.get(eventType)

    if (! callbacks) {
        callbacks = new Set
        subscribers.set(eventType, callbacks)
    }

    callbacks.add(callback)
}

export const subscribeOnce = (source, eventType, callback) => {
    if (! (source && eventType && callback)) {
        return
    }

    const wrapper = detail => {
        callback(detail)
        unsubscribe(source, eventType, wrapper)
    }

    subscribe(source, eventType, wrapper)
}

export const unsubscribe = (source, eventType, callback) => {
    if (! (source && eventType && callback)) {
        return
    }

    const subscribers = sources2subscribers.get(source)

    if (! subscribers) {
        return
    }

    const callbacks = subscribers.get(eventType)
    callbacks?.delete(callback)
}
