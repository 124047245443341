import {useState} from 'react'
import {Input, Select} from 'antd'
import CheckboxFilter from '../CheckboxFilter.jsx'
import FilterDropdown from '../FilterDropdown.jsx'
import DateRangePicker from './DateRangePicker.jsx'

const sort = {
    asc: (a = '', b = '') => a.localeCompare(b),
    desc: (a = '', b = '') => b.localeCompare(a),
}

const InputFilterRange = ({
    mode,
    range,
    setMode,
    setRange,
}) => {
    const options = [
        {label: '之间', value: 'between'},
        {label: '之外', value: 'except'},
    ]

    const css = {
        display: 'flex !important',
    }

    return (
        <Input.Group
            css={css}
            compact
        >
            <Select
                size="small"
                options={options}
                value={mode}
                onChange={setMode}
            />

            <DateRangePicker
                allowClear
                size="small"
                value={range}
                onChange={setRange}
            />
        </Input.Group>
    )
}

const defaultGetDate = (a) => a

const FilterDate = ({
    confirm,
    getDate = defaultGetDate,
    getValues,
    setFilter,
    setSort,
}) => {
    const [keyword, setKeyword] = useState('')
    const [mode, setMode] = useState('between')
    const [order, setOrder] = useState('')
    const [range, setRange] = useState([])
    const [selectedDates, setSelectedDates] = useState([])
    const [min = '0000-00-00', max = '9999-99-99'] = range

    const filterDate = {
        between: (value = '') => min <= value && value <= max,
        except: (value = '') => value < min || max < value,
    }[mode]

    const dates = getValues().map(getDate)
    const datesForCheck = dates.filter(filterDate)

    if (order) {
        datesForCheck.sort((a, b) => sort[order](a, b))
    }

    const handleConfirm = () => {
        if (
            0 < selectedDates.length &&
            selectedDates.length < dates.length
        ) {
            setFilter(
                (value) => {
                    const date = getDate(value)
                    return selectedDates.includes(date)
                }
            )
        }
        else {
            setFilter(null)
        }

        if (order) {
            setSort((a, b) => {
                const dateA = getDate(a)
                const dateB = getDate(b)
                return sort[order](dateA, dateB)
            })
        }
        else {
            setSort(null)
        }

        confirm()
    }

    const handleReset = () => {
        setKeyword('')
        setMode('between')
        setOrder('')
        setRange([])
        setSelectedDates([])
    }

    const css = {
        width: 320,
    }

    return (
        <FilterDropdown
            css={css}
            order={order}
            onChangeOrder={setOrder}
            onConfirm={handleConfirm}
            onReset={handleReset}
        >
            <InputFilterRange
                mode={mode}
                range={range}
                setMode={setMode}
                setRange={setRange}
            />

            <CheckboxFilter
                keyword={keyword}
                value={selectedDates}
                values={datesForCheck}
                onChange={setSelectedDates}
                onChangeKeyword={setKeyword}
            />
        </FilterDropdown>
    )
}

export default FilterDate
