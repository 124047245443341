import {useState} from 'react'
import CheckboxFilter from '../CheckboxFilter.jsx'
import FilterDropdown from '../FilterDropdown.jsx'

const sort = {
    asc: (a = '', b = '') => a.localeCompare(b),
    desc: (a = '', b = '') => b.localeCompare(a),
}

const defaultGetText = (a) => (a || 0 === a) ? String(a) : ''

const FilterText = ({
    confirm,
    getText = defaultGetText,
    getValues,
    setFilter,
    setSort,
}) => {
    const [keyword, setKeyword] = useState('')
    const [selectedTexts, setSelectedTexts] = useState([])
    const [order, setOrder] = useState('')
    const getTrimmedText = (a) => getText(a)?.trim() ?? ''

    const texts = Array.from(new Set(
        getValues().map(getTrimmedText)
    ))

    if (order) {
        texts.sort((a, b) => sort[order](a, b))
    }

    const handleConfirm = () => {
        if (
            0 < selectedTexts.length &&
            selectedTexts.length < texts.length
        ) {
            setFilter(
                (value) => {
                    const text = getTrimmedText(value)
                    return selectedTexts.includes(text)
                }
            )
        }
        else {
            setFilter(null)
        }

        if (order) {
            setSort((a, b) => {
                const textA = getTrimmedText(a)
                const textB = getTrimmedText(b)
                return sort[order](textA, textB)
            })
        }
        else {
            setSort(null)
        }

        confirm()
    }

    const handleReset = () => {
        setKeyword('')
        setOrder('')
        setSelectedTexts([])
    }

    return (
        <FilterDropdown
            order={order}
            onChangeOrder={setOrder}
            onConfirm={handleConfirm}
            onReset={handleReset}
        >
            <CheckboxFilter
                keyword={keyword}
                value={selectedTexts}
                values={texts}
                onChange={setSelectedTexts}
                onChangeKeyword={setKeyword}
            />
        </FilterDropdown>
    )
}

export default FilterText
