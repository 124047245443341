export default (path, params = {}) => {
    const refinedParams = Object.fromEntries(
        Object.entries(params)
            .filter(
                ([key, value]) => ! (
                    undefined === key ||
                    null === key ||
                    undefined === value ||
                    null === value
                )
            )
            .map(
                ([key, value]) => [
                    key,
                    'object' === typeof value ? JSON.stringify(value) : value
                ]
            )
    )

    const qs = String(new URLSearchParams(refinedParams))
    return qs ? `${path}?${qs}` : path
}
