import {useEffect, useRef, useState} from 'react'

export default (state, setState) => {
    const refSetStates = useRef(new Set)
    const refArgs = useRef()
    refArgs.current = [state, setState]

    return [
        x => {
            const [state, setState] = refArgs.current
            const newState = 'function' === typeof x ? x(state) : x
            setState(newState)

            for (const setState of refSetStates.current) {
                setState(newState)
            }
        },

        () => {
            const [observedState, setObservedState] = useState(state)

            useEffect(
                () => {
                    refSetStates.current.add(setObservedState)
                    return () => refSetStates.current.delete(setObservedState)
                },

                []
            )

            return observedState
        },
    ]
}
