import {Checkbox} from 'antd'

export default (table) => {
    const {striped} = table.useConfig()
    const prefix = <Checkbox checked={striped} />

    const onClick = e => {
        table.setConfig(cfg => ({...cfg, striped: ! cfg.striped}))
        e.preventDefault()
    }

    return {
        key: 'row-stripe',
        label: '奇偶行纹路',
        prefix,
        onClick,
    }
}
