const enhanceOnRow = ({
    onRow,
    selectRowOnClick,
    table,
}) => {
    if (! table.rowSelection) {
        return onRow
    }

    const {
        indexFromKey,
        rowKey,
        rows,
        rowSelection: {onChange, selectedRowKeys, type}
    } = table

    return (row) => {
        const key = row[rowKey]
        const props = onRow?.(row) ?? {}

        // 为虚拟列表补齐样式
        if (selectedRowKeys.includes(key)) {
            if (props.className) {
                props.className += ' ant-table-row-selected'
            }
            else {
                props.className = 'ant-table-row-selected'
            }
        }

        if (! selectRowOnClick) {
            return props
        }

        const selectRows = (selectedRowKeys) => {
            const selectedRows = selectedRowKeys.map(
                (key) => rows[indexFromKey(key)]
            )

            onChange(selectedRowKeys, selectedRows)
        }

        const isCheckboxDisabled = () => {
            const props = table.rowSelection.getCheckboxProps?.(row)
            return props?.disabled
        }

        const handleClick = {
            checkbox: () => {
                if (isCheckboxDisabled()) {
                    return
                }

                if (selectedRowKeys.includes(key)) {
                    const keys = selectedRowKeys.filter(
                        (k) => k !== key
                    )

                    selectRows(keys)
                }
                else {
                    selectRows([...selectedRowKeys, key])
                }
            },

            radio: () => {
                if (isCheckboxDisabled()) {
                    return
                }

                selectRows([key])
            }
        }[type]

        return {
            ...props,

            onClick: (...args) => {
                handleClick?.(...args)
                props.onClick?.(...args)
            }
        }
    }
}

export default enhanceOnRow
