import {css} from '@emotion/react'
import ColorPicker from '@/components/Form/ColorPicker.jsx'

const cssPicker = css({
    aspectRatio: '1',
})

export default (table, columnKey) => {
    const {color} = table.useColumnConfig(columnKey)

    const handleChange = color => {
        table.setColumnConfig(columnKey, cfg => ({...cfg, color}))
    }

    const suffix = (
        <ColorPicker
            css={cssPicker}
            value={color}
            onChange={handleChange}
        />
    )

    return {
        key: 'column-fg-color',
        label: '前景色',
        suffix,
        onClick: e => e.preventDefault(),
    }
}
