import {Divider, Space} from 'antd'
import FilterFoot from './FilterFoot.jsx'
import FilterHead from './FilterHead.jsx'

const FilterDropdown = ({
    onChangeOrder,
    onConfirm,
    onReset,
    order,
    children,
    ...props
}) => {
    const cssDropdown = {
        width: 240,
        padding: '12px 16px',
    }

    return (
        <Space
            css={cssDropdown}
            direction="vertical"
            {...props}
        >
            <FilterHead
                order={order}
                onChange={onChangeOrder}
            />

            <Divider css={{margin: 0}} />

            {children}

            <FilterFoot
                onConfirm={onConfirm}
                onReset={onReset}
            />
        </Space>
    )
}

export default FilterDropdown
