import {Input} from 'antd'
import InputNumber from '@/components/Form/InputNumber.jsx'

const InputRange = ({
    placeholderMax = '最大',
    placeholderMin = '最小',
    size,
    value: [min, max] = [],
    onChange,
    ...props
}) => {
    const getNumber = (value) => {
        if (value || 0 === value) {
            return value
        }
        else {
            return undefined
        }
    }

    const handleChangeMax = (value) => {
        onChange([min, getNumber(value)])
    }

    const handleChangeMin = (value) => {
        onChange([getNumber(value), max])
    }

    const css = {
        display: 'flex !important',
    }

    return (
        <Input.Group
            css={css}
            compact
            {...props}
        >
            <InputNumber
                placeholder={placeholderMin}
                size={size}
                value={min}
                onChange={handleChangeMin}
            />

            <InputNumber
                placeholder={placeholderMax}
                size={size}
                value={max}
                onChange={handleChangeMax}
            />
        </Input.Group>
    )
}

export default InputRange
