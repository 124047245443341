const makeColumnRowNumber = (table) => {
    const rk2num = new Map(
        table.refinedRows.map((row) => {
            const rk = row[table.rowKey]
            return [rk, table.numFromKey(rk)]
        })
    )

    const numMax = Array.from(rk2num.values()).reduce(
        (max, n) => Math.max(max, n),
        0
    )

    const width = 40 + Math.max(0, String(numMax).length - 3) * 10

    return {
        dataIndex: '$num',
        align: 'center',
        fixed: true,
        width,

        onCell: () => ({
            style: {
                backgroundColor: '#fafafa',
                textAlign: 'center',
            },
        }),

        render: (_, row) => {
            const rk = row[table.rowKey]
            return rk2num.get(rk)
        },
    }
}

export default makeColumnRowNumber
