import {Button} from 'antd'

const FilterFoot = ({onConfirm, onReset, ...props}) => {
    const css = {
        display: 'flex',
        justifyContent: 'space-between',
    }

    return (
        <div
            css={css}
            {...props}
        >
            <Button
                size="small"
                onClick={onReset}
            >重置</Button>

            <Button
                size="small"
                type="primary"
                onClick={onConfirm}
            >确定</Button>
        </div>
    )
}

export default FilterFoot
