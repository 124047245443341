export default (table) => {
    const onClick = () => {
        table.setConfig({})
    }

    return {
        key: 'reset-table',
        label: '重置表格设置',
        onClick,
    }
}
