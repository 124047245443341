import { Tag } from 'antd';

export const getStateName = (value) => {
    switch (value) {
        case "DRAFT":
            return "草稿";
        case "REVISE":
            return "修订";
        case "RLS":
            return "发布";
        case "DISCARD":
            return "废弃";
    }
    return value;
}

const StateData = ({ value }) => {
    switch (value) {
        case "DRAFT":
            return <Tag color="#DCDCDC">草稿</Tag>;
        case "REVISE":
            return <Tag color="blue">修订</Tag>;
        case "RLS":
            return <Tag color="green">发布</Tag>;
        case "DISCARD":
            return <Tag color="red">废弃</Tag>;
    }
    return <div>{value}</div>;
}

export default StateData;