import {Checkbox} from 'antd'

export default (table, columnKey) => {
    const {columns} = table.useConfig()
    const {align} = columns.find(e => e.id === columnKey)
    const prefixCenter = <Checkbox checked={'center' === align} />
    const prefixLeft = <Checkbox checked={'left' === align} />
    const prefixRight = <Checkbox checked={'right' === align} />

    const handleClickCenter = () => {
        table.setColumnConfig(columnKey, cfg => ({
            ...cfg,
            align: 'center' === cfg.align ? '' : 'center',
        }))
    }

    const handleClickLeft = () => {
        table.setColumnConfig(columnKey, cfg => ({
            ...cfg,
            align: 'left' === cfg.align ? '' : 'left',
        }))
    }

    const handleClickRight = () => {
        table.setColumnConfig(columnKey, cfg => ({
            ...cfg,
            align: 'right' === cfg.align ? '' : 'right',
        }))
    }

    return [
        {
            key: 'align-content-center',
            label: '居中对齐',
            prefix: prefixCenter,
            onClick: handleClickCenter,
        },

        {
            key: 'align-content-left',
            label: '左对齐',
            prefix: prefixLeft,
            onClick: handleClickLeft,
        },

        {
            key: 'align-content-right',
            label: '右对齐',
            prefix: prefixRight,
            onClick: handleClickRight,
        },
    ]
}
