import AutoComplete from '@/components/Form/AutoComplete.jsx'
import Select from '@/components/Form/Select.jsx'
import {DEFAULT_CHANGE_DELAY} from '../config.mjs'

const InputDict = ({
    allowInput,
    changeDelay = DEFAULT_CHANGE_DELAY,
    ...props
}) => {
    return allowInput ?
        <AutoComplete
            bordered={false}
            changeDelay={changeDelay}
            {...props}
        />
        :
        <Select
            bordered={false}
            {...props}
        />
}

export default InputDict
