import {useRef} from 'react'
import {css} from '@emotion/react'
import {Table as AntdTable} from 'antd'
import Button from './Button.jsx'
import diffTable from './diffTable.mjs'
import enhanceColumns from './enhanceColumns.jsx'
import enhanceOnRow from './enhanceOnRow.mjs'
import enhanceSummary from './enhanceSummary.jsx'
import {TableContextProvider, useTableContext} from './TableContext.jsx'
import TableContent from './TableContent.jsx'
import useRowNum from './useRowNum.mjs'
import useTable from './useTable.mjs'
import useApplyConfig from './useApplyConfig.mjs'
import ButtonCreateRows from './topButtons/ButtonCreateRows.jsx'
import ButtonDeleteRows from './topButtons/ButtonDeleteRows.jsx'
import ButtonMoveRowsDown from './topButtons/ButtonMoveRowsDown.jsx'
import ButtonMoveRowsUp from './topButtons/ButtonMoveRowsUp.jsx'
import TopButton from './topButtons/TopButton.jsx'
import FilterBoolean from './dataTypes/Boolean/FilterBoolean.jsx'
import FilterDate from './dataTypes/Date/FilterDate.jsx'
import FilterDict from './dataTypes/Dict/FilterDict.jsx'
import FilterNumber from './dataTypes/Number/FilterNumber.jsx'
import FilterText from './dataTypes/Text/FilterText.jsx'
import Image from './dataTypes/Image/Image.jsx'
import ImagePicker from './dataTypes/Image/ImagePicker.jsx'
import InputBoolean from './dataTypes/Boolean/InputBoolean.jsx'
import InputDate from './dataTypes/Date/InputDate.jsx'
import InputDict from './dataTypes/Dict/InputDict.jsx'
import InputLongText from './dataTypes/LongText/InputLongText.jsx'
import InputNumber from './dataTypes/Number/InputNumber.jsx'
import InputText from './dataTypes/Text/InputText.jsx'
import ViewBoolean from './dataTypes/Boolean/ViewBoolean.jsx'
import ViewDate from './dataTypes/Date/ViewDate.jsx'
import ViewDict from './dataTypes/Dict/ViewDict.jsx'
import ViewLongText from './dataTypes/LongText/ViewLongText.jsx'
import ViewNumber from './dataTypes/Number/ViewNumber.jsx'
import ViewText from './dataTypes/Text/ViewText.jsx'

const cssTable = css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
})

const cssTableStriped = css({
    '.ant-table-row': {
        '&:nth-child(even)': {
            backgroundColor: '#fff',
        },

        '&:nth-child(even) .ant-table-cell-fix-left': {
            backgroundColor: 'inherit',
        },

        '&:nth-child(odd)': {
            backgroundColor: '#fafafa',
        },

        '&:nth-child(odd) .ant-table-cell-fix-left': {
            backgroundColor: 'inherit',
        },

        '&:hover .ant-table-cell-fix-left': {
            backgroundColor: '#fafafa',
        }
    }
})

const Table = ({
    className,
    style,
    config,
    configurable,
    dataSource,
    noRowNumber,
    rowHeight,
    rowKey,
    rowNumberTitle,
    rowSelection,
    selectRowOnClick,
    table = {},
    top,
    beforeChange,
    beforeCreateRow,
    beforeDeleteRow,
    beforeUpdateRow,
    onChange,
    onChangeConfig,
    ...props
}) => {
    const refEl = useRef()
    const {getRowNum, setRowNum} = useRowNum()

    Reflect.defineProperty(table, 'dom', {get() {return refEl.current}})

    useTable({
        config,
        configurable,
        columns: props.columns,
        dataSource,
        expandable: props.expandable,
        getRowNum,
        rowKey,
        rowSelection,
        setRowNum,
        table,
        beforeChange,
        beforeCreateRow,
        beforeDeleteRow,
        beforeUpdateRow,
        onChange,
        onChangeConfig,
    })

    useApplyConfig(table)

    const columns = enhanceColumns({
        noRowNumber,
        rowHeight,
        rowNumberTitle,
        rowSelection,
        table,
    })

    const onRow = enhanceOnRow({
        onRow: props.onRow,
        selectRowOnClick,
        table,
    })

    // TODO: columnsConfig
    const summary = enhanceSummary({
        noRowNumber,
        rowSelection,
        summary: props.summary,
        table,
    })

    const pagination = false === props.pagination ?
        false
        :
        {
            defaultPageSize: 20,
            position: ['bottomRight'],
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: total => `总记录数 ${total} 条`,
            size: 'small',
            ...props.pagination,

            onShowSizeChange: (current, size) => {
                table.setConfig(cfg => ({
                    ...cfg,
                    pagination: {...cfg.pagination, pageSize: size}
                }))

                props.pagination?.onShowSizeChange?.(current, size)
            },

            ...table.config.pagination,
        }

    const csss = [cssTable]

    if (table.config.striped) {
        csss.push(cssTableStriped)
    }

    return (
        <div
            ref={refEl}
            css={csss}
            className={className}
            style={style}
        >
            <TableContextProvider value={table}>
                {top}

                <TableContent
                    {...props}
                    style={{marginTop: top ? 12 : 0}}
                    columns={columns}
                    dataSource={table.refinedRows}
                    onRow={onRow}
                    summary={summary}
                    pagination={pagination}
                    rowHeight={rowHeight}
                    rowKey={table.rowKey}
                    rowSelection={table.rowSelection}
                />
            </TableContextProvider>
        </div>
    )
}

Object.assign(Table, {
    Button,
    ButtonCreateRows,
    ButtonDeleteRows,
    ButtonMoveRowsDown,
    ButtonMoveRowsUp,
    diffTable,
    FilterBoolean,
    FilterDate,
    FilterDict,
    FilterLongText: FilterText,
    FilterNumber,
    FilterText,
    Image,
    ImagePicker,
    InputBoolean,
    InputDate,
    InputDict,
    InputLongText,
    InputNumber,
    InputText,
    Summary: AntdTable.Summary,
    TopButton,
    useContext: useTableContext,
    ViewBoolean,
    ViewDate,
    ViewDict,
    ViewLongText,
    ViewNumber,
    ViewText,
})

export default Table
