import {Button, Modal} from 'antd'
import {fileFromClipboard} from '@/script/file.mjs'

const ButtonPasteImage = ({onChange, ...props}) => {
    const handleClick = async () => {
        try {
            const file = await fileFromClipboard(
                'image/png',
                `${crypto.randomUUID()}.png`
            )

            if (! file) {
                Modal.error({content: '未从剪贴板读取到图片数据'})
                return
            }

            onChange(file)
        }
        catch (err) {
            Modal.error({content: err.message})
        }
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}

export default ButtonPasteImage
