const indexOfColumn = (table, columnKey) => {
    const elTitle = table.querySelector(`[data-column-key="${columnKey}"]`)
    const th = elTitle.closest('th')
    return Array.prototype.indexOf.call(th.parentElement.children, th)
}

export default (table, columnKey) => {
    const onClick = () => {
        const t = table.dom.cloneNode(true)
        t.removeAttribute('id')
        t.removeAttribute('name')

        Object.assign(t.style, {
            position: 'absolute',
            zIndex: -1,
            opacity: 0,
            pointerEvents: 'none',
        })

        const tables = t.querySelectorAll('table')

        for (const tbl of tables) {
            tbl.style.width = 'max-content'
        }

        const colgroups = t.querySelectorAll('colgroup')

        for (const colgroup of colgroups) {
            for (const col of colgroup.children) {
                col.removeAttribute('style')
            }
        }

        document.body.appendChild(t)
        const index = indexOfColumn(t, columnKey)

        const width = Math.max(
            colgroups[0].children[index].getBoundingClientRect().width,
            colgroups[1].children[index].getBoundingClientRect().width,
        )

        t.remove()
        table.setColumnConfig(columnKey, cfg => ({...cfg, width}))
    }

    return {
        key: 'fit-content',
        label: '列宽适配内容',
        onClick: onClick,
    }
}
